.numberCircle {
    display:inline-block;
    line-height:0px;  
    border-radius:50%;       
  }
  .flex-row-center{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .flex-row-between{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .flex-row-start{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .flex-col-center{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .matchDayList .modal-content{
    background-color: transparent;
    box-shadow:none
  }
  .center-div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .w-25{
    width:25%
  }