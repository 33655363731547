button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    transition: all .2s ease-in-out;
}

button:focus {
    outline: none;
}

.top-bar {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 20px;
    background: #535353;
}

.top-bar .team {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30%;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
}

.top-bar span {
    color: #fff;
}

.figures-left, .figures-right {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50px;
    background: #313131;
}

.figures-left {
    border-radius: 0 25px 0 0;
}

.figures-right {
    border-radius: 25px 0 0 0;
    /* margin-left: auto; */
}

.middle-figures {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 200px;
    border-radius: 0 0 20px 20px;
    background: #00d1e1;
}

.middle-figures .figures-left, .middle-figures .figures-right {
    background: #02c0ce;
}

.timer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100px; */
}

.deep-details {
    display: grid;
    column-gap: 10px;
    grid-template-columns: auto auto auto;
}

.block-title span {
    font-weight: 500;
}

.block-title .set {
    height: 30px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transform: translateY(20px); */
    background: #313131;
    color: #fff;
    font-weight: 500;
    border-radius: 15px 15px 0 0 ;
}

.deep-details .list-group-item {
    position: relative!important;
    background: transparent!important;
    border: none;
    padding: 0.5rem 1.25rem;
    text-transform: uppercase;
    /* font-size: 0.9vw; */
    font-weight: 500;
    color: #fff;
}

.deep-details .list-group-item .badge {
    background: transparent!important;
    color: #fff;
}

.deep-details .list-group-item:nth-child(even) {
    background: #00d1e1!important;
}

.on-court-left, .on-court-right {
    height: 250px;
    overflow: hidden;
    margin-top: 6px;
    background: #02c0ce;
}

.on-benche-left, .on-benche-right {
    min-height: 250px;
    overflow: hidden;
    /* margin-top: 6px; */
    background: #535353;
}

.on-court-left {
    border-radius: 15px 0 0 0;
}

.on-benche-left {
    border-radius: 0 0 0 15px;
}

.on-court-left .list-group-item::before {
    content: '';
    position: absolute;
    right: 65px;
    height: 100%;
    width: 3px;
    background-color: #02c0ce;
}

.on-benche-left .list-group-item::before {
    content: '';
    position: absolute;
    right: 65px;
    height: 100%;
    width: 3px;
    background-color: #535353;
}

.on-court-right .list-group-item::before {
    content: '';
    position: absolute;
    left: 65px;
    height: 100%;
    width: 3px;
    background-color: #02c0ce;
}

.on-benche-right .list-group-item::before {
    content: '';
    position: absolute;
    left: 65px;
    height: 100%;
    width: 3px;
    background-color: #535353;
}

.on-court-right {
    border-radius: 0 15px 0 0;
}

.on-benche-right {
    border-radius: 0 0 15px 0;
}

.field-card {
    position: relative;
    display: grid;
    column-gap: 3px;
    grid-template-columns: auto auto auto auto;
    height: 250px;
    width: 100%;
    overflow: hidden;
    background: #535353;
}

.ball-posession {
    font-size: 15px;
    color: #00d1e1;
}

.ball-posession.ball-left {
    position: absolute;
    bottom: 5px;
    left: 10px;
}

.ball-posession.ball-right {
    position: absolute;
    top: 0px;
    right: 10px;
}

.field-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    background: #707070;
}

.field-row:nth-child(1) {
    background: #5c5c5c!important;
}

.field-row:nth-child(2) {
    border-right: 2px solid #02c0ce;
}

.field-row:nth-child(3) {
    border-left: 2px solid #02c0ce;
}

.field-row:nth-child(4) {
    background: #5c5c5c!important;
}

.field-row:nth-child(1) .player-avatar,
.field-row:nth-child(2) .player-avatar {
    border: 2px solid #00d2e1b7;
}

.field-row:nth-child(3) .player-avatar,
.field-row:nth-child(4) .player-avatar {
    border: 2px solid #e2e2e2;
}

.field-row .player {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.field-row .player .player-avatar {
    height: 45px;
    width: 45px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 3px;
}

.field-row .player p {
    font-size: 8px;
    margin-bottom: 0;
    text-transform: uppercase;
}

.field-row .player span {
    position: absolute;
    right: 0;
    transform: translate(3px, 1px);
    font-size: 12px;
    font-weight: 600;
    margin-right: -5px;
}


.field-row .player-avatar img {
    height: 40px;
}

.point-assignment {
    position: relative;
    display: grid;
    column-gap: 10px;
    grid-template-columns: auto auto;
}

.point-assignment-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin-top: 32px;
    color: #fff;
    background: #02c0ce;
}

.point-assignment-btn:hover {
    background: #5c5c5c;
}

/* .notification {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 100%;
    margin-top: 32px;
    font-size: 12px;
    color: #fff;
    background: #02c0ce;
} */

.input-table {
    min-height: 192px;
    width: 100%;
    margin-top: 10px;
    border: 1px solid #5c5c5c67;
}

.input-table .table-head {
    display: grid;
    /* column-gap: 10px; */
    grid-template-columns: auto auto auto auto auto auto auto auto;
    height: 30px;
    width: 100%;
    padding: 0 10px;
    background: #00d1e1;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
}

.input-table .table-head .table-title  {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-table .table-head .table-title:nth-child(4),
.input-table .table-head .table-title:nth-child(5)  {
    background: #02c0ce;
}

.input-table .table-data {
    display: grid;
    /* column-gap: 10px; */
    grid-template-columns: auto auto auto auto auto auto auto auto;
    height: 46px;
    width: 100%;
    padding: 0 5px;
    font-size: 12px;
}

.input-table .data  {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-table .data:nth-child(4),
.input-table .data:nth-child(5)  {
    font-weight: 700;
    color: #02c0ce;
}

.bottom-actions {
    margin-top: 10px;
}

.bottom-actions .action {
    margin-bottom: 5px;
    height: 40px;
    transition: all .2s ease-in-out;
}

.left-actions .action, .right-actions .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ebebeb;
}
/* 
.left-actions .action:hover, .right-actions .action:hover {
    background: #707070;
    color: #fff;
} */

.bottom-actions .label {
    display: flex;
    justify-content: center;
    width: 85%;
    font-weight: 500;
    cursor: pointer;
}

/* .bottom-actions .time-out-left,
.bottom-actions .substitution-left {
    border-bottom-left-radius: 20px;
} */

.bottom-actions .time-out-right,
.bottom-actions .substitution-right {
    border-bottom-right-radius: 20px;
}

.bottom-actions .figures, .bottom-actions .figures {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50px;
    color: #fff;
}

.bottom-actions .time-out-left .figures,
.bottom-actions .substitution-left .figures {
    border-bottom-left-radius: 20px;
}

.bottom-actions .time-out-right .figures,
.bottom-actions .substitution-right .figures {
    border-bottom-right-radius: 20px;
}

.bottom-actions .time-out .figures {
    background: #fa9931;
}

.bottom-actions .substitution .figures {
    background: #02c0ce;
}

.middle-actions {
    position: relative;
    display: grid;
    column-gap: 10px;
    grid-template-columns: auto auto;
}

.middle-actions .saction {
    background: #E56969;
}

.middle-actions .saction:hover {
    background: #707070;
}

.middle-actions .challenge {
    background: #e9e7e7;
}

/* .middle-actions .challenge:hover {
    background: #707070;
} */

.bottom-actions.left-actions, .bottom-actions.right-actions {
    position: relative;
    display: grid;
    column-gap: 10px;
    grid-template-columns: auto auto;
}

.bottom-actions .action {
    margin-bottom: 5px;
    height: 40px;
    transition: all .2s ease-in-out;
}

.left-actions .action, .right-actions .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ebebeb;
}

.left-actions .action:hover, .right-actions .action:hover {
    background: #707070;
    color: #fff;
}

.bottom-actions .label {
    display: flex;
    justify-content: center;
    width: 85%;
    font-weight: 500;
    cursor: pointer;
}

.bottom-actions .injury-right,
.bottom-actions .libero-right {
    border-bottom-right-radius: 20px;
    /* border-left: #c5c5c5 1px solid; */
}

.bottom-actions .injury-left,
.bottom-actions .libero-left {
    border-bottom-left-radius: 20px;
    /* border-right: #c5c5c5 1px solid; */
}

.bottom-actions .libero-left,
.bottom-actions .libero-right {
    color: #00d1e1;
}

.bottom-actions .injury-left,
.bottom-actions .injury-right {
    color: #E56969;
}

.middle-actions .LHS .action, .middle-actions .RHS .action {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    color: #fff;
}

.floating-left-buttons {
    /* left: 20px; */
}

.floating-buttons {
    /* position: absolute; */
    /* top: 40%;
    right: 20px; */
    /* transform: translateY(-70px); */
    /* display: flex; */
    /* flex-direction: column; */
    width: 70px;
}

.floating-buttons .inner {
    position: relative;
    display: flex;
    flex-direction: column;
}

.floating-buttons button {
    margin: 10px;
    padding: 10px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.floating-buttons button:hover {
    font-size: 20px;
}

.floating-buttons small {
    font-size: 10px;
}

.floating-buttons .history {
    background: #fff;
    border: 2px solid #02c0ce;
    color: #02c0ce;
}

.floating-buttons .history:hover {
    background: #02c0ce;
    border: 2px solid #02c0ce;
    color: #fff;
}

.floating-buttons .TT {
    margin: 10px;
    padding: 10px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #02c0ce;
    color: #ffff;
    font-weight: 600;
}

.floating-buttons .TT:hover {
    background: #707070;
    color: #ffff;
}

.floating-buttons .stop {
    background: #E56969;
}

.floating-buttons .exit {
    background: #8c69e5;
}

.floating-buttons .inner .pop-notification {
    position: absolute;
    top: -30px;
    right: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 300px;
    border-radius: 10px 20px 0 10px;
    box-shadow: 3px 3px 7px #70707096;
    padding: 20px;
    background: #313131;
    color: #fff;
    opacity: 0.9;
}

.floating-buttons .inner .pop-notification span {
    font-size: 14px;
    margin: 0;
}

.floating-buttons .inner .pop-notification .undo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px!important;
    width: 80px!important;
    background: #fff;
    color: #313131;
    border-radius: 5px;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.floating-buttons .inner .pop-notification .undo:hover {
    background: #535353;
    color: #fff;
}


.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none;
    }
  }
  .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
  }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
      transition: none;
    }
  }
  .accordion-button:hover {
    z-index: 2;
  }
  .accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
  
  .accordion-header {
    margin-bottom: 0;
  }
  
  .accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  .accordion-item:not(:first-of-type) {
    border-top: 0;
  }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
  }
  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  .accordion-body {
    padding: 1rem 1.25rem;
  }
  
  .accordion-flush .accordion-collapse {
    border-width: 0;
  }
  .accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  .accordion-flush .accordion-item:first-child {
    border-top: 0;
  }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0;
  }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
  }
  